
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface Material {
  item: string;
  qty: number;
  required: boolean;
}

interface OrganizerValues {
  calendly_link: string;
  materialsToBring: Array<Material>;
}

interface FieldtripField extends Field {
  value: never;
  setup: OrganizerValues;
}

export default defineComponent({
  name: 'MFieldTripRSVP',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Field Trip RSVP';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          console.log(field.name);
          if (field.name) {
            return field.name === 'm-field-trip-rsvp';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const currentTab = ref<'rsvp' | 'whattobring'>('rsvp');

    const fieldValue = computed<FieldtripField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const organizerValues = computed<OrganizerValues>({
      get: () => {
        if (!fieldValue.value.setup) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.setup = {
            calendly_link: '',
            materialsToBring: []
          };
        }
        return fieldValue.value.setup;
      },
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    const userIsEmployer = ref(_props.userType === 'organizer');

    const forKeys = organizerValues.value.materialsToBring.map(
      () => `${Math.random() + Date.now()}`
    );

    return {
      currentTab,
      organizerValues,
      headers: [
        { text: 'Item', align: 'start', value: 'item' },
        { text: 'Quantity', align: 'end', value: 'qty' },
        { text: 'Required', align: 'end', value: 'required' }
      ],
      userIsEmployer,
      forKeys
    };
  }
});
