import activity from './m-field-trip-rsvp.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Field Trip',
  icon: 'mdi-bus',
  description: 'Pilotcity Field Trip',
  styling: { borderColor: '#C3177E' },
  status: true,
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-field-trip-rsvp',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        materialsToBring: [{ item: 'Computer', qty: 1, required: 'Yes' }]
      },
      value: {}
    },
    {
      roles: ['employer'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
